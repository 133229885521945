import {Api, RouteConfigBuilder} from "@/calico-vue/service";

/**
 * routes
 */
const Input = () => import(/* webpackChunkName: "useraccount" */ "./Input");
const Confirm = () => import(/* webpackChunkName: "useraccount" */ "./Confirm");

const basePath = "/useraccount/email";

const Routes = new RouteConfigBuilder(basePath, {
  title: "パスワード変更"
});

export const routes = [
  Routes.baseRedirect("input"),
  Routes.component("input", Input),
  Routes.component("confirm", Confirm),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  confirmCode(code) {
    return api.execute("confirm_code", { code: code });
  },
  sendConfirmationCode(email) {
    return api.execute("send_confirmation_code", { email: email });
  },
};
