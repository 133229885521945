import Vue from "vue";
import VueRouter from "vue-router";
//import * as uiv from "uiv";
//import uivLocaleJa from "uiv/src/locale/lang/ja-JP";
import "sugar-language";
import * as Init from "@/calico-vue/init";
import {CONFIG} from "@/config/config";
import * as COMPONENTS from "@/config/components";
import * as DIRECTIVES from "@/config/directives";
import * as FILTERS from "@/config/filters";
import {authInfo} from "@/lib/service";
import {ANNOUNCE_TYPES} from "@/config/announcements";
import {API_HANDLERS} from "@/config/api-handlers";
import {BUTTON_TYPES} from "@/config/buttons";
import {TABLE_TYPES} from "@/config/tables";
import {router} from "./router";
import store from "./store";
import axios from "axios";
import JSONbig from "json-bigint";
import App from "./App.vue";

/**
 * config
 */
const config = Init.config;
config.setData(CONFIG);

/**
 * Vue.config
 */
for (let key in config.get("vue.config", [])) {
  Vue.config[key] = config.get("vue.config." + key);
}
Vue.config.errorHandler = Init.createErrorHandler(config);

/**
 * Plugins
 */
Vue.use(VueRouter);
//Vue.use(uiv, { locale: uivLocaleJa });
Vue.use(Init.ComponentsPlugin, COMPONENTS);
Vue.use(Init.DirectivesPlugin, DIRECTIVES);
Vue.use(Init.FiltersPlugin, FILTERS);
Vue.use(Init.AuthPlugin, authInfo);
Vue.use(Init.MovePlugin);
Vue.use(Init.ErrorPlugin);
Vue.use(Init.AnnouncePlugin, ANNOUNCE_TYPES);
Vue.use(Init.ProcessingPlugin);
Vue.use(Init.ExtEnumPlugin);

/**
 * 設定
 */
Init.setApiHandlers(API_HANDLERS);
Init.setButtonTypes(BUTTON_TYPES);
Init.setTableTypes(TABLE_TYPES);

/**
 * long対応
 */
axios.defaults.transformResponse = [JSONbig.parse];

/**
 * 初期化
 */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
