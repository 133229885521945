<template>
  <header class="l-header">
    <div class="l-header__inner">
      <a class="l-header__logo" @click="$move('/home')"><img src="/assets/img/logo-blue.svg" alt="Jet">my Page</a>
      <div class="l-header__user" v-if="!noAuth">
        <p class="l-header__name js-header-user"><span>{{currentMember ? currentMember.name + 'さん' : ''}}</span></p>
        <div class="l-header__menu">
          <div class="l-header__nav">
            <ul class="l-header__link">
              <li><a class="c-link__arrow" @click="$move('/useraccount/profile/')">アカウント情報</a></li>
              <li><a class="c-link__arrow" @click="$move('/useraccount/password/')">パスワード変更</a></li>
              <li><a class="c-link__arrow" @click="$move('/useraccount/email/input')">メールアドレス変更</a></li>
            </ul>
            <div class="members" v-if="members.length > 1">
              <ul class="l-header__link">
                <li v-for="m in members" :key="m.memberId">
                  <a class="c-link__arrow" @click="switchMember(m.memberId)">{{m.name}} さん</a>
                </li>
              </ul>
            </div>
            <button class="c-btn__logout" type="button" @click="logout"><span>ログアウト</span></button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {AuthService} from "@/lib/service";
import {mapState} from "vuex";

export default {
  props: {
    noAuth: Boolean
  },
  computed: mapState([
      "members",
      "currentMember"
  ]),
  methods: {
    logout() {
      this.$store.commit("clearNotification");
      AuthService.logout();
    },
    switchMember(memberId) {
      this.$store.commit("chooseMember", memberId);
      this.$router.go({ path: "/home", force: true });
    }
  }
}
</script>

<style scoped>
.members {
  margin: 1rem 0;
  border-top: 1px solid #00A0E2;
  border-bottom: 1px solid #00A0E2;
  padding: 0.5rem 0;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
