import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/admin";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'admin' */ "./Index");

const Routes = new RouteConfigBuilder(basePath, {
  title: "管理者用メニュー",
  basePath,
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const BatchService = {
  getList() {
    return api.execute("/batch/get_list");
  },
};
