<template>
  <div class="c-error__text" v-if="errors[name]">
    <div v-for="m in errors[name]" :key="m">
      {{m}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
    name: String
  },
  methods: {
    hide () {
      this.$store.commit("clearNotification");
    }
  }
}
</script>
