import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/admin/env";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: 'admin-env' */ "./Index");

const Routes = new RouteConfigBuilder(basePath, {
  title: "環境情報",
  basePath,
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getInfo() {
    return api.execute("get_info");
  },
};
