import {Api, RouteConfigBuilder} from "@/calico-vue/service";

const basePath = "/admin/current_time";

/**
 * routes
 */
const Index = () =>
  import(/* webpackChunkName: 'admin-current_time' */ "./Index");

const Routes = new RouteConfigBuilder(basePath, {
  title: "現在日時設定",
  basePath,
});
export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  saveForm() {
    return api.execute("save_form");
  },
  save(form) {
    return api.execute("save", form);
  },
  reset() {
    return api.execute("reset");
  },
};
