<template>
  <div id="app" :class="{'l-wrap': true, development}">
    <router-view :key="$route.fullPath" v-if="routerViewShown"/>
  </div>
</template>

<script>
import {EventBus} from '@/calico-vue/service';
import Vue from "vue";

export default {
  data () {
    return {
      routerViewShown: true,
      development: Vue.config.productionTip
    }
  },
  created () {
    //reload対応
    EventBus.$on('reload-router-view', () => {
      this.routerViewShown = false;
      this.$nextTick(() => {
        this.routerViewShown = true;
      });
    });
  },
  mounted () {
  }
};
</script>

<style lang="scss" src="./styles/style.scss"></style>
