import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    members: [],
    currentMember: null,
    registrationToken: null,
    notification: { hidden: true }
  },
  getters: {
    otherMembers(state) {
      return state.members.filter(m => m.memberId != state.currentMember.memberId);
    }
  },
  mutations: {
    setMembers(state, members) {
      state.members = members;
      if (state.currentMember) {
        const memberId = state.currentMember.memberId;
        state.currentMember = state.members.find(member => member.memberId === memberId);
      }
      if (!state.currentMember) {
        state.currentMember = members[0];
      }
    },
    setDefaultMember(state, memberId) {
      state.currentMember = { memberId };
    },
    chooseMember(state, memberId) {
      state.currentMember = state.members.find(member => member.memberId === memberId);
    },
    setRegistrationToken(state, token) {
      state.registrationToken = token;
    },
    clearRegistrationToken(state) {
      state.registrationToken = null;
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = { hidden: true };
    },
    hideNotification(state) {
      state.notification.hidden = true;
    }
  },
  actions: {
    notify (context, notification) {
      const duration = notification.duration == undefined ? 3000 : notification.duration;
      context.commit('setNotification', {
        type: notification.type,
        message: notification.message,
        hidden: false,
        keep: duration > 0
      });
      if (duration > 0) {
        setTimeout(() => {
          context.commit('hideNotification');
        }, duration);
      }
    }
  },
  plugins: [
      createPersistedState({
        key: 'jet-mypage',
        storage: window.sessionStorage
      })
  ]
});
