import {Api, RouteConfigBuilder} from "@/calico-vue/service";

/**
 * routes
 */
const Routes = new RouteConfigBuilder();

const Home = () => import(/* webpackChunkName: "home" */ "./Home");
const Exam = () => import(/* webpackChunkName: "home" */ "./Exam");

export const routes = [
  Routes.redirect("/", "/home"),
  Routes.component("/home", Home),
  Routes.component("/exam/:examineeId", Exam)
];

/**
 * Service
 */
const api = new Api("");

export const Service = {
  getExaminees(memberId) {
    return api.execute("exam/get_examinees", { memberId });
  },
  getMembers() {
    return api.execute("useraccount/get_members");
  },
  issueCbtUrl(examineeId) {
    return api.execute("exam/issue_cbt_url", { examineeId });
  }
};
