import {config, DateUtil} from "../../service";

function formatDate(value, format) {
  if (value == null) return null;
  return DateUtil.format(value, format);
}

/**
 * https://day.js.org/docs/en/display/format
 */
export function year(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.year", "YYYY")
  );
}
export function month(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.month", "YYYY/MM")
  );
}
export function date(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.date", "YYYY/MM/DD")
  );
}
export function time(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.time", "HH:mm")
  );
}
export function datetime(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.datetime", "YYYY/MM/DD HH:mm")
  );
}
export function timestamp(value, format = null) {
  return formatDate(
    value,
    format || config.get("filter.date.format.timestamp", "YYYY/MM/DD HH:mm:ss")
  );
}
