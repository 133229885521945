import { safeAccess } from "@/calico-vue/service";
import { AuthService } from "@/lib/service";
import store from "@/store";

export const API_HANDLERS = {
  default: {
    then: [(res) => res.data],
    catch: [defaultErrorHandle],
    finally: [],
  },
};

/**
 * defaultErrorHandle
 */
function defaultErrorHandle(cause) {
  if (cause.response.status / 100 === 5) {
    store.dispatch("notify", {
      type: "danger",
      message: "サーバーエラーが発生しました。",
      duration: 0,
    });
    throw cause;
  }

  const error = safeAccess(cause, "response.data");
  if (error == null) {
    store.commit("notify", {
      type: "danger",
      message: "サーバーと正しく通信できませんでした。",
      duration: 0,
    });
    throw cause;
  }

  if (error.type === "SessionTimeoutException") {
    AuthService.onSessionTimeout();
  }

  store.dispatch("notify", {
    type: "danger",
    message: error.message,
    duration: 0,
  });
  throw error;
}
