import FileSaver from "file-saver";

export default {
  download(media) {
    const decoded = atob(media.payload);
    let bytes = new Uint8Array(decoded.length);
    for (let i = 0, len = decoded.length; i < len; i++) {
      bytes[i] = decoded.charCodeAt(i);
    }
    const file = new File([bytes.buffer], media.meta.name, {
      type: media.meta.type,
    });
    FileSaver.saveAs(file);
  },
};
