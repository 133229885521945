<template>
  <div v-if="notification.type" class="c-message" :class="['is-' + notification.type, notification.hidden ? 'hidden' : '']" @click="hide">
    <p class="u-tac">{{notification.message}}</p>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: mapState([
      "notification"
  ]),
  methods: {
    hide () {
      this.$store.commit("clearNotification");
    }
  }
}
</script>
