import {Api, RouteConfigBuilder} from "@/calico-vue/service";
import store from '@/store';

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: "useraccount" */ "./Index");

const basePath = "/useraccount/profile";

const Routes = new RouteConfigBuilder(basePath, {
  title: "会員情報"
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index)
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  getMember() {
    return api.execute("get_member", { memberId: store.state.currentMember.memberId });
  },
  save(form) {
    return api.execute("update", form);
  },
  prefectures() {
    return api.execute("/system/extenum/get", { type: "Prefecture" })
  }
};
