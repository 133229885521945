import VueRouter from "vue-router";
import * as appRoutes from "@/app/routes";
import NotFound from "@/app/NotFound";
import {authInfo, AuthService} from "@/lib/service";
import store from "@/store"

/**
 * routes定義
 */
let routes = [];
for (const key in appRoutes) {
  routes.push(...appRoutes[key]);
}
routes.push({
  path: "*",
  component: NotFound,
  meta: { allowAll: true },
});

/**
 * VueRouter初期化
 */
export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) {
      return false;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/**
 * keep
 * 認証情報の最新化とセッション時間延長
 */
router.beforeEach((to, from, next) => {
  AuthService.keep().then(
    () => {
      next();
    },
    () => {
    }
  );
});

/**
 * ログインチェック
 */
router.beforeEach((to, from, next) => {
  const allowAll = to.matched.every((route) => route.meta.allowAll);
  if (allowAll || authInfo.authenticated) {
    next();
  } else {
    next({ path: "/login" });
  }
});

/**
 * ログインチェック
 */
router.afterEach(() => {
  if (!store.state.notification.keep)
    store.commit('clearNotification')
});
