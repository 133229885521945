import {Api, RouteConfigBuilder} from "@/calico-vue/service";

/**
 * routes
 */
const Index = () => import(/* webpackChunkName: "useraccount" */ "./Index");

const basePath = "/useraccount/password";

const Routes = new RouteConfigBuilder(basePath, {
  title: "パスワード変更"
});

export const routes = [
  Routes.baseRedirect("index"),
  Routes.component("index", Index),
];

/**
 * Service
 */
const api = new Api(basePath);

export const Service = {
  save(form) {
    return api.execute("update", form);
  },
};
